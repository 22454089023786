<template>
  <div class="file-upload input-group">
    <form class="file-upload__form" method="POST" enctype="multipart/form-data">
      <label ref="label" class="file-upload__label accent" for="file_upload">
        {{ label }}
      </label>
      <input
        id="file_upload"
        ref="input"
        class="file-upload__input"
        type="file"
        name="file"
        accept="audio/*"
        @change="getFile"
      />
    </form>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'FileUpload',
  data: () => ({
    file: undefined,
    fileReader: new FileReader(),
    label: 'Upload File',
  }),
  computed: {
    ...mapState({
      mode: ({ mode }) => mode,
    }),
  },
  watch: {
    mode: function (mode) {
      if (mode === 'file') {
        this.$refs.label.click();
      }
    },
  },
  mounted() {
    this.fileReader.onloadend = (event) => {
      this.$store.dispatch('setFilePath', event.target.result);
    };
  },
  methods: {
    getFile() {
      const { input } = this.$refs;
      this.fileReader.readAsDataURL(input.files[0]);
      this.$store.dispatch('setFileName', input.files[0].name);
      this.label = input.files[0].name;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';

.file-upload {
  display: none;
  background: $white-900;
  border-radius: rem(5);

  input {
    display: none;
  }

  label {
    display: inline-block;
    padding: rem(10) rem($grid-gutter);

    &:hover {
      color: $dark-purple;
    }
  }
}
</style>