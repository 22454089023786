import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    track: {},
    sourceNode: {},
    analyserNode: {},
    analyserSmoothing: 0.8,
    gridWaves: true,
    files: [],
    filePath: '',
    fileName: '',
    mode: undefined,
    barHeight: 0,
    controlBarOpen: true,
  },
  actions: {
    getTrack: async ({ commit }, file) => {
      // const endpoint = `https://staging.alibimusic.com/api/v1/tracks/${id}`;
      // const req = await fetch(endpo/int);
      // const body = await req.json();
      commit('SET_TRACK', file);
    },
    setSourceNode: async ({ commit }, node) => {
      commit('SET_SOURCE_NODE', node);
    },
    setAnalyser: async ({ commit }, analyserNode) => {
      commit('SET_ANALYSER_NODE', analyserNode);
    },
    setMode: async ({ commit }, mode) => {
      commit('SET_MODE', mode);
    },
    setFilePath: async ({ commit }, path) => {
      commit('ADD_FILE', path);
      commit('SET_FILE_PATH', path);
    },
    setFileName: async ({ commit }, name) => {
      commit('SET_FILE_NAME', name);
    },
    setBarHeight: async ({ commit }, height) => {
      commit('SET_BAR_HEIGHT', height);
    },
    setAnalyserSmoothing: async ({ commit, state }, value) => {
      if (state.analyserNode) {
        state.analyserNode.smoothingTimeConstant = value;
        commit('SET_ANALYSER_SMOOTHING', value);
      }
    },
    setGridWaves: async({ commit, state }, value) => {
      if (state.gridWaves !== value) {
        commit('SET_GRID_WAVES', value);
      } else {
        console.warn(`gridWaves is already set to ${value}`);
      }
    },
    setControlBarState: ({ commit }, payload) => {
      commit('SET_CONTROL_BAR_STATE', payload);
    },
  },
  mutations: {
    SET_MODE: (state, payload) => {
      state.mode = payload;
    },
    SET_TRACK: (state, payload) => {
      state.track = payload;
    },
    SET_SOURCE_NODE: (state, payload) => {
      state.sourceNode = payload;
    },
    SET_ANALYSER_NODE: (state, payload) => {
      state.analyserNode = payload;
    },
    SET_FILE_PATH: (state, payload) => {
      state.filePath = payload;
    },
    SET_FILE_NAME: (state, payload) => {
      state.fileName = payload;
    },
    ADD_FILE: (state, payload) => {
      state.files.push(payload);
    },
    SET_BAR_HEIGHT: (state, payload) => {
      state.barHeight = payload;
    },
    SET_ANALYSER_SMOOTHING: (state, payload) => {
      state.analyserSmoothing = payload;
    },
    SET_GRID_WAVES: (state, payload) => {
      state.gridWaves = payload;
    },
    SET_CONTROL_BAR_STATE: (state, payload) => {
      state.controlBarOpen = payload;
    },
  },
  modules: {}
})
