<template>
  <div id="app" :class="{ 'visualizer--grid': true }">
    <!-- <visualizer /> -->
    <grid-visualizer ref="canvas" />
    <file-info v-if="mode === 'file' && fileName" />
    <control-bar-toggle />
    <control-bar :open="controlBarOpen">
      <mode-select slot="mode-select" />
      <file-upload slot="file-upload" />
      <user-settings slot="user-settings" />
      <player slot="player" />
    </control-bar>
  </div>
</template>

<script>
// import Visualizer from './components/Visualizer';
import GridVisualizer from './components/GridVisualizer';
import ControlBar from './components/ControlBar';
import ControlBarToggle from './components/ControlBarToggle';
import Player from './components/Player';
import ModeSelect from './components/ModeSelect';
import FileUpload from './components/FileUpload';
import UserSettings from './components/UserSettings';
import FileInfo from './components/FileInfo';
import { mapState } from 'vuex';

export default {
  name: 'App',
  metaInfo: {
    title: 'Vaporwave Visualizer | Frequency Visualization',
    htmlAttrs: {
      lang: 'en',
    },
    meta: [
      { charset: 'utf-8' },
      {
        name: 'viewport',
        content: 'width=device-width, initial-scale=1',
      },
      {
        property: 'og:title',
        content: 'Vaporwave | Frequency Visualizer',
        vmid: 'og:title',
      },
      {
        name: 'theme-color',
        content: '#A537C7',
      },
    ],
    noscript: [
      {
        innerHTML: 'This site requires JavaScript, and you have it disabled :(',
      },
    ],
  },
  components: {
    // Visualizer,
    GridVisualizer,
    ControlBar,
    Player,
    ModeSelect,
    FileUpload,
    UserSettings,
    FileInfo,
    ControlBarToggle,
  },
  computed: {
    ...mapState({
      fileName: ({ fileName }) => fileName,
      mode: ({ mode }) => mode,
      barHeight: ({ barHeight }) => barHeight,
      controlBarOpen: ({ controlBarOpen }) => controlBarOpen,
    }),
  },
  watch: {
    controlBarOpen() {
      this.$nextTick(this.setHeight);
    },
  },
  mounted() {
    this.setHeight();
    window.addEventListener('resize', this.setHeight);
    console.log('By Nick Alcantara. Say hi @ https://nickalcantara.com');
    // window.addEventListener('dblclick', () => {
    //     const fullscreenElement = document.fullscreenElement || document.webkitFullscreenElement;
    //     const { $el: canvas } = this.$refs.canvas;

    //     if (!fullscreenElement) {
    //         if (canvas.requestFullscreen) {
    //             canvas.requestFullscreen();
    //         } else if (canvas.webkitRequestFullscreen) {
    //             canvas.webkitRequestFullscreen();
    //         }
    //     } else {
    //         if (document.exitFullscreen) {
    //             document.exitFullscreen();
    //         } else {
    //             document.webkitExitFullscreen();
    //         }
    //     }
    // });    
  },
  methods: {
    setHeight() {
      if (this.controlBarOpen) {
        this.$el.style.height = `${window.innerHeight - this.barHeight}px`;
      } else {
        this.$el.removeAttribute('style');
      }
    }
  }
};
</script>

<style lang="scss">
@import '@/styles/global.scss';
@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@400;600&family=Barlow:wght@400;500;700&display=swap');

html,
body {
  height: 100%;
}

html {
  font-family: $body-font;
}

body {
  margin: 0;
  background: $black-900;
  font-family: $body-font;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#app {}

.visualizer--grid {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

@mixin all-headings {
  font-family: $heading-font;
  font-weight: 600;
}

.body-sm {
  font-size: rem(14);
  line-height: lh(18, 14);
  letter-spacing: rem(0.4);
}

.accent {
  font-family: $body-font;
  font-size: rem(16);
  line-height: lh(18, 16);
  font-weight: 600;
  text-transform: uppercase;
}

.button,
%button {
  appearance: none;
  border: 0;
  border-radius: rem(3);
  padding: rem(10) rem(20);
  background: $white-900;
  font-size: rem(18);
  line-height: lh(18, 16);
  font-family: $heading-font;
  font-weight: 700;
  letter-spacing: rem(0.4);
  text-transform: uppercase;
  transition: background 0.2s ease-in-out, color 0.2s ease-in-out;
}

.button--primary,
%button--primary {
  color: $purple;

  &:hover {
    color: $blue;
  }
}
</style>
