<template>
  <canvas />
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'GridVisualizer',
  data: () => ({
    dataArray: undefined,
    bufferLength: 0,
    context: {},
    rows: {},
    columnRowCount: 0,
    tileMaxWidth: 0,
    tileMaxHeight: 0,
    raf: 0,
    gridSize: 0,
    rtl: true,
  }),
  computed: {
    ...mapState({
      analyserNode: ({ analyserNode }) => analyserNode,
      barHeight: ({ barHeight }) => barHeight,
      waves: ({ gridWaves }) => gridWaves
    }),
  },
  watch: {
    analyserNode: function (available) {
      if (available) {
        this.bufferLength = this.analyserNode.frequencyBinCount;
        this.columnRowCount = Math.sqrt(this.bufferLength);
        this.dataArray = new Uint8Array(this.bufferLength);
        this.draw();
      }
    },
    barHeight: function () {
      this.setDimensions();
    },
    waves: function () {
      this.setDimensions();
    }
  },
  mounted() {
    this.context = this.$el.getContext('2d');
    this.setDimensions();
    this.addListeners();
  },
  beforeDestroy() {
    this.removeListeners();
  },
  methods: {
    addListeners() {
      window.addEventListener('resize', this.setDimensions);
      window.addEventListener('dblclick', () => {
        this.$nextTick(this.setDimensions);
      })
    },
    removeListeners() {
      window.removeEventListener('resize', this.setDimensions);
    },
    setDimensions() {
      cancelAnimationFrame(this.raf);
      this.gridSize = 0;

      if (window.innerWidth >= window.innerHeight) {
        this.gridSize = window.innerHeight * 0.8;
      } else if (window.innerWidth < window.innerHeight) {
        this.gridSize = window.innerWidth * 0.8;
      }

      this.$el.width = this.gridSize;

      if (this.waves) {
        this.$el.height = this.gridSize + (3 * (this.gridSize / this.columnRowCount))
      } else {
        this.$el.height = this.gridSize;
      }

      this.tileMaxWidth = this.$el.offsetWidth / this.columnRowCount;
      this.tileMaxHeight = this.$el.offsetHeight / this.columnRowCount;

      if (this.analyserNode.fftSize) {
        this.draw();
      }
    },
    draw() {
      // Frequency data is composed of integers on a scale from 0 to 255.
      this.analyserNode.getByteFrequencyData(this.dataArray);

      this.context.fillStyle = 'rgb(0, 0, 0)';
      if (this.waves) {
        this.context.fillRect(0, 0, this.gridSize, this.gridSize + (3 * (this.gridSize / this.columnRowCount)));
      } else {
        this.context.fillRect(0, 0, this.gridSize, this.gridSize);
      }

      let x = this.tileMaxWidth / -2;
      let y = this.gridSize - (this.tileMaxHeight / 2);

      if (this.waves) {
        y += (3 * (this.gridSize / this.columnRowCount));
      }

      let rtl = true;

      for (let i = 0; i < this.bufferLength; i++) {
        let nextRow = false;
        let computedY;

        const normalizedDecibal = this.normalize(this.dataArray[i], 255);
        const tileWidth = normalizedDecibal * this.tileMaxWidth;
        const radius = tileWidth / 2;

        const computedX = this.rtl ? x + this.tileMaxWidth : x - this.tileMaxWidth;

        if (this.waves) {
          const maxJumpHeight = this.tileMaxHeight * 2.5;
          const jumpHeight = normalizedDecibal * maxJumpHeight;
          computedY = (y - radius) - jumpHeight;
        } else {
          computedY = (y - this.tileMaxHeight) - (tileWidth / 2);
        }

        const red = normalizedDecibal * 255;
        const green = 255 - red;
        const blue = 0;

        this.context.strokeStyle = this.context.fillStyle = `rgb(${red}, ${green},${blue})`;

        this.context.beginPath();
        this.context.arc(computedX, computedY, radius, 0, Math.PI * 2);
        this.context.fill();

        const atBoundary = (i + 1) % this.columnRowCount === 0;
        if (atBoundary) {
          nextRow = true;
          rtl = !rtl;
        } else if (rtl) {
          x += this.tileMaxWidth;
        } else {
          x -= this.tileMaxWidth;
        }

        if (nextRow) {
          y -= this.tileMaxHeight;
        }
      }

      this.raf = requestAnimationFrame(this.draw);
    },
    normalize(val, max) {
      return val / max;
    },    
  }
}
</script>

<style lang="scss" scoped>
canvas:-webkit-full-screen {
  width: 100% !important;
  border: 1px solid red;
}
canvas:-moz-full-screen {
  width: 100% !important;
  border: 1px solid red;
}
canvas:-ms-fullscreen {
  width: 100% !important;
  border: 1px solid red;
}
canvas:fullscreen {
  width: 100% !important;
  border: 1px solid red;
}
</style>