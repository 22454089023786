<template>
  <div class="file-info" :style="{ bottom: `${barHeight + 10}px` }">
    <h1 v-if="fileName" class="accent">{{ fileName }}</h1>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'FileInfo',
  computed: {
    ...mapState({
      fileName: ({ fileName }) => fileName,
      barHeight: ({ barHeight }) => barHeight,
    }),
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';

.file-info {
  position: fixed;
  left: 50%;
  width: calc(100% - #{$grid-gutter-sm * 2}px);
  padding: rem(10) rem($grid-gutter);
  background: rgba($white-900, 0.35);
  border-radius: rem(5);
  backdrop-filter: blur(4px);
  transform: translateX(-50%);

  h1 {
    color: $white-900;
  }
}

@media(max-width: $bp-tablet) {
  .file-info {
    display: none;
  }
}

@media (min-width: $bp-tablet) {
  .file-info {
    width: auto;
  }
}
</style>