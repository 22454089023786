<template>
  <div class="user-settings">
    <button class="user-settings__toggle accent" @click="toggleOpen">
      Settings
    </button>
    <div
      ref="contextMenu"
      class="user-settings__context-menu"
      :class="{ 'user-settings__context-menu--open': menuOpen }"
    >
      <div class="user-settings__context-menu-border" />
      <div class="user-settings__context-menu-liner">
        <div class="user-settings__context-menu-option">
          <label for="Smoothing" class="accent">Smoothing</label>
          <input
            id="smoothing"
            v-model="analyserSmoothing"
            type="range"
            step="0.01"
            min="0.8"
            max="0.99"
          />
        </div>
        <div class="user-settings__context-menu-option">
          <label for="waves" class="accent">Waves</label>
          <input
            id="waves"
            v-model="waves"
            type="checkbox"
          >
        </div>
        <span class="user-settings__copyright accent">
          &copy; {{ new Date().getFullYear() }}
          <a href="https://nickalcantara.com?from=vv" target="_blank">Nick Alcantara</a>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserSettings',
  data: () => ({
    menuOpen: false,
  }),
  computed: {
    analyserSmoothing: {
      get() {
        return this.$store.state.analyserSmoothing;
      },
      set(value) {
        this.$store.dispatch('setAnalyserSmoothing', value);
      },
    },
    waves: {
      get() {
        return this.$store.state.gridWaves;
      },
      set(value) {
        this.$store.dispatch('setGridWaves', value);
      }
    }
  },
  methods: {
    toggleOpen(event) {
      event.stopPropagation();
      this.menuOpen = !this.menuOpen;

      if (this.menuOpen) {
        document.body.addEventListener('click', this.clickOutside);
      } else {
        document.body.removeEventListener('click', this.clickOutside);
      }
    },
    clickOutside(event) {
      if (!this.menuOpen) return;
      const path = event.composedPath();

      if (!path.includes(this.$refs.contextMenu)) {
        this.menuOpen = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';

.user-settings {
  display: flex;
  position: relative;
  flex-flow: column;
  justify-content: center;
  align-items: flex-start;
  padding: rem(10) rem($grid-gutter);
  background: $white-900;
  border-radius: rem(5);
}

.user-settings__toggle {
  @include unstyle-button;
}

.user-settings__context-menu {
  position: absolute;
  top: 0;
  left: 50%;
  opacity: 0;

  border-radius: rem(5);
  transform: translate(-50%, calc(-100% - 1em));
  pointer-events: none;
  visibility: hidden;
  transition: opacity 0.2s ease-in-out;

  &::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 50%;
    border-top: rem(8) solid $white-900;
    border-right: rem(8) solid transparent;
    border-bottom: rem(8) solid transparent;
    border-left: rem(8) solid transparent;
    transform: translate(-50%, 100%);
  }
}

.user-settings__context-menu-border {
  position: absolute;
  top: rem(-2);
  left: rem(-2);
  right: rem(-2);
  bottom: rem(-2);
  border-radius: rem(5);
  background: linear-gradient(
    to bottom right,
    $blue,
    $purple,
    $dark-purple,
    $pink
  );
  z-index: -1;
}

.user-settings__context-menu-liner {
  padding: rem(10) rem($grid-gutter);
  background: $white-900;
  border-radius: rem(5);
}

.user-settings__context-menu--open {
  opacity: 1;
  pointer-events: auto;
  visibility: visible;
}

.user-settings__copyright {
  display: inline-block;
  margin-top: rem(12);
  font-size: rem(10);
}

@media (min-width: $bp-landscape) {
  .user-settings__context-menu {
    transform: translate(-25%, calc(-100% - 1em));
    &::after {
      left: 25%;
      transform: translate(0%, 100%);
    }
  }
}

@media (min-width: $bp-desktop) {
  .user-settings__context-menu {
    left: 0;
    /* transform: translate(-50%, -100%); */

    &::after {
      left: unset;
      right: rem(10);
      transform: translate(-50%, 100%);
    }
  }
}
</style>