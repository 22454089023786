<template>
  <div class="control-bar" :class="{ 'visually-hidden': !open }">
    <button
      class="control-bar__close-button"
      aria-label="close menu"
      @click="$store.dispatch('setControlBarState', false)"
    >
      <span class="control-bar__close-button-text">&times;</span>
    </button>
    <div class="control-bar__inner">
      <div class="control-bar__mode-select">
        <slot name="mode-select" />
      </div>
      <div class="control-bar__player">
        <slot name="player" />
      </div>
      <div class="control-bar__file-upload">
        <slot name="file-upload" />
      </div>
      <div class="control-bar__user-settings">
        <slot v-if="analyserNode" name="user-settings" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'ControlBar',
  props: {
    open: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    ...mapState({
      analyserNode: ({ analyserNode }) => analyserNode,
    }),
  },
  mounted() {
    this.dispatchHeight();
    this.addListeners();
  },
  methods: {
    addListeners() {
      window.addEventListener('resize', this.dispatchHeight);
    },
    dispatchHeight() {
      this.$store.dispatch('setBarHeight', this.$el.offsetHeight);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';

.control-bar { 
  position: absolute;
  display: flex;
  align-items: flex-end;
  bottom: 0;
  left: 0;
  width: 100%;
  height: percentage(1 / 3);
  min-height: rem(270);
  background: $white-700;
  // background: linear-gradient(
  //   to right,
  //   rgba($blue, 0.9),
  //   rgba($dark-purple, 0.9),
  //   rgba($purple, 0.9),
  //   rgba($pink, 0.9)
  // );

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: -2px;
    width: 100%;
    height: rem(10);
    opacity: 1;
    // background: linear-gradient(to right, #2d4af1, #6c23e5, #a537c7, #d244a9);
    background: $white-700;
    filter: blur(10px);
    z-index: -1;
  }
}

.control-bar__close-button {
  appearance: none;
  position: absolute;
  top: 0;
  right: rem(16);
  width: rem(40);
  height: rem(40);
  transform: translateY(-50%);
  border: 2px solid $purple;
  border-radius: 50%;
}

.control-bar__close-button-text {
  font-size: rem(24);
  text-align: center;
}

.visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
} 

.control-bar__inner {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.control-bar__mode-select,
.control-bar__file-upload,
.control-bar__user-settings {
  box-shadow: 0 0 rem(5) 0 rgba($black-700, 0.4);
}

@media (min-width: $bp-landscape) {
  .control-bar {
    position: fixed;
    bottom: 0;
    height: unset;
    min-height: unset;
  }

  .control-bar__inner {
    display: grid;
    place-items: center;
    grid-template: auto / 1fr;
    row-gap: rem(10);
    grid-template: auto / repeat(2, 1fr);
    justify-items: start;
    column-gap: rem(10);
    width: 100%;
    padding: rem(10) rem($grid-gutter-sm);
    overflow: visible;
  }
  .control-bar__user-settings {
    grid-row: 2;
  }
}

@media (min-width: $bp-desktop) {
  .control-bar__close-button {
    top: unset;
    bottom: rem(64);
    left: 50%;
    right: unset;
    width: rem(60);
    height: rem(60);
    transform: translate(-50%, -50%);
  }

  .control-bar__inner {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-flow: row nowrap;
    padding: rem(18) rem($grid-gutter);
  }

  .control-bar__player {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .control-bar__user-settings {
    margin-left: rem($grid-gutter);
  }
}
</style>