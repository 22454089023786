<template>
  <div class="mode-select">
    <span v-if="noMode" class="mode-select__intro accent">Select Mode</span>
    <div
      class="mode-select__microphone"
      :class="{ 'mode-select--active': mode === 'microphone' }"
    >
      <input
        id="microphone"
        v-model="mode"
        type="radio"
        value="microphone"
        name="mode"
      />
      <label class="accent" for="microphone">Microphone</label>
    </div>
    <div
      class="mode-select__file"
      :class="{ 'mode-select--active': mode === 'file' }"
    >
      <input id="file" v-model="mode" type="radio" value="file" name="mode" />
      <label class="accent" for="file" @click="requestFile">File</label>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'ModeSelect',
  computed: {
    ...mapState({
      track: ({ track }) => track,
    }),
    mode: {
      get() {
        return this.$store.state.mode;
      },
      set(mode) {
        this.$store.dispatch('setMode', mode);
      },
    },
    noMode() {
      return typeof this.mode !== 'string';
    },
  },
  methods: {
    requestFile() {
      if (this.mode === 'file') {
        this.$store.dispatch('setMode', '');
        this.$nextTick(() => {
          this.$store.dispatch('setMode', 'file');
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';

@keyframes float {
  0% {
    transform: translate(-50%, calc(-100% + -1.25em));
  }

  50% {
    transform: translate(-50%, calc(-100% + -1em));
  }

  100% {
    transform: translate(-50%, calc(-100% + -1.25em));
  }
}
.mode-select {
  display: flex;
  position: relative;
  border-radius: rem(5);
  background: rgba($white-900, 0.65);
}

.mode-select--active {
  border-radius: rem(5);
  background: $white-900;

  label {
    color: $purple;
  }
}

.mode-select__intro {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 50%;
  width: 12ch;
  text-align: center;
  background: $white-900;
  padding: rem(5) rem(12);
  border-radius: rem(5);
  animation: float 0.8s forwards infinite;
}

label {
  display: inline-block;
  padding: rem(10) rem($grid-gutter);
  transition: background-color 0.15s ease-in-out, color 0.2s ease-in-out;
}

input {
  display: none;
}
</style>