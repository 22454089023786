<template>
  <div class="control-bar-toggle" :class="{ 'bar-open': controlBarOpen }">
    <button
      class="control-bar-toggle__button"
      @click="controlBarOpen = !controlBarOpen"
    >
      <div class="control-bar-toggle__button-text accent">
        Menu
      </div>
    </button>
  </div>
</template>

<script>

export default {
  name: 'ControlBarToggle',
  computed: {
    controlBarOpen: {
      get() {
        return this.$store.state.controlBarOpen;
      },
      set(value) {
        this.$store.dispatch('setControlBarState', value);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';

.control-bar-toggle {
  position: absolute;
  bottom: rem(64);
  left: 50%;
  width: rem(60);
  height: rem(60);
  transform: translate(-50%, -50%);
  z-index: 1;

  &.bar-open {
    display: none;
  }
}

.control-bar-toggle__button {
  position: relative;
  appearance: none;
  border: 0;
  width: rem(60);
  height: rem(60);
  text-align: center;
  background: $black-900;
  border: 2px solid $white-900;
  border-radius: 50%;
  opacity: 0.5;
  transition: background 0.2s ease, border-color 0.2s ease, opacity 0.2s ease;

  &:hover {
    opacity: 1;
    background: $white-900;
    border-color: $purple;

    .control-bar-toggle__button-text {
      color: $black-900;
    }
  }
}

.control-bar-toggle__button-text {
  position: absolute;
  top: 50%;
  left: 50%;
  color: $white-900;
  transform: translate(-50%, -50%);
  transition: color 0.2s ease;
}

@media (min-width: $bp-desktop) {
  .control-bar-toggle {
    bottom: rem(4);
  }
}
</style>