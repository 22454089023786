<template>
  <audio
    controls
    :src="filePath"
    :class="`audio-node__mode--${mode}`"
  />
</template>

<script>
import { mapState } from 'vuex';

// const tracks = [
//   require('../assets/alibi_tiger.mp3'),
//   require('../assets/01_royalty.mp3'),
//   require('../assets/02_freaks_and_geeks.mp3'),
//   require('../assets/02_we_aint_them.mp3'),
//   require('../assets/03_one_up.mp3'),
//   require('../assets/04_black_faces.mp3'),
//   require('../assets/05_unnecessary.mp3'),
//   require('../assets/06_shoulda_known.mp3'),
//   require('../assets/07_rip.mp3'),
//   require('../assets/08_american_royalty.mp3'),
//   require('../assets/09_glamour.mp3'),
//   require('../assets/10_toxic.mp3'),
//   require('../assets/11_silk_pillow.mp3'),
//   require('../assets/12_they_dont_like_me.mp3'),
//   require('../assets/13_arrangement.mp3'),
//   require('../assets/14_wont_stop.mp3'),
//   require('../assets/15_bronchitis.mp3'),
//   require('../assets/16_wonderful.mp3'),
//   require('../assets/17_make_it_go_right.mp3'),
//   require('../assets/18_real_estate.mp3'),
//   require('../assets/for_the_windu.mp3'),
//   require('../assets/tf_bg.wav'),
//   require('../assets/atd_final.mp3'),
// ];

const validActions = ['ENTER', 'ARROWRIGHT', 'ARROWLEFT'];

const playerMachine = {
  initial: 'idle',
  states: {
    idle: {
      on: {
        ENTER: 'playing',
        ARROWRIGHT: 'next',
        ARROWLEFT: 'previous',
      },
    },
    playing: {
      on: {
        ENTER: 'paused',
        ARROWRIGHT: 'next',
        ARROWLEFT: 'previous',
      },
    },
    paused: {
      on: {
        ENTER: 'playing',
        ARROWRIGHT: 'next',
        ARROWLEFT: 'previous',
      },
    },
    next: {
      on: {
        ENTER: 'playing',
        ARROWRIGHT: 'next',
        ARROWLEFT: 'previous',
      },
    },
    previous: {
      on: {
        ENTER: 'playing',
        ARROWRIGHT: 'next',
        ARROWLEFT: 'previous',
      },
    },
  },
};

export default {
  name: 'Player',
  data: () => ({
    context: new AudioContext(),
    sourceNode: {},
    userMediaNode: undefined,
    mediaElementNode: undefined,
    analyser: {},
    playerMachine,
    status: playerMachine.initial,
  }),
  computed: {
    ...mapState({
      mode: ({ mode }) => mode,
      filePath: ({ filePath }) => filePath,
    }),
  },
  watch: {
    mode: async function (mode, previousMode) {
      if (typeof previousMode !== 'string') {
        this.context.resume();
      }

      this.setSourceNode(mode);
    },
  },
  mounted() {
    this.$nextTick(async () => {
      // save the media source of the audio element
      if (!this.mediaElementNode) {
        this.mediaElementNode = this.context.createMediaElementSource(this.$el);
      }
      // get an analyser
      this.analyser = this.context.createAnalyser();
      // connect the default to the analyser if one is set.
      if (this.mode) {
        this.setSourceNode(this.mode);
        this.sourceNode.connect(this.analyser);
      }
      // pipe the sourceNode -> analyser into the destination (speakers/headphones).
      this.analyser.connect(this.context.destination);
      this.$store.dispatch('setAnalyser', this.analyser);
    });
    this.addListeners();
  },
  beforeDestroy() {
    this.removeListeners();
  },
  methods: {
    async setSourceNode(mode) {
      if (mode === 'microphone') {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: false,
        });
        if (!this.userMediaNode) {
          this.userMediaNode = this.context.createMediaStreamSource(stream);
        }

        if (this.mediaElementNode) {
          this.mediaElementNode.disconnect();
        }

        this.sourceNode = this.userMediaNode;
        this.analyser.disconnect();
      }

      if (mode === 'file') {
        if (this.userMediaNode) {
          this.userMediaNode.disconnect();
        }

        this.sourceNode = this.mediaElementNode;
        this.analyser.connect(this.context.destination);
      }

      if (!this.$el.paused) {
        this.$el.pause();
      }

      this.sourceNode.connect(this.analyser);
    },
    addListeners() {
      window.addEventListener('keyup', this.message);
    },
    removeListeners() {
      window.removeEventListener('keyup', this.message);
    },
    message(event) {
      const action = event.key.toUpperCase();
      if (validActions.includes(action)) {
        this.status = this.playerMachine.states[this.status].on[action];
        this.handleStatusUpdate();
      }
    },
    handleStatusUpdate() {
      switch (this.status) {
        case 'playing': {
          this.$el.play();
          break;
        }
        case 'paused': {
          this.$el.pause();
          break;
        }
        case 'next': {
          if (this.tracks[this.track + 1]) {
            this.track += 1;
          } else {
            this.track = 0;
          }
          break;
        }
        case 'previous': {
          if (this.tracks[this.track - 1]) {
            this.track -= 1;
          } else {
            this.track = this.tracks.length - 1;
          }
          break;
        }
        default: {
          return this.status;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.audio-node__mode--microphone {
  opacity: 0;
}
</style>